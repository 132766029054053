import { Box } from '@mui/system'
import React from 'react'

export const EmptyResult = () => {
  return (
    <Box py={25} display="flex" justifyContent="center">
       <Box component={'p'} textAlign="center" sx={{backgroundColor:'#F08080', maxWidth:500, p:3, borderRadius:4, color:'#ffffff'}}>
            არსებულ ნაწილზე შეთავაზება არ არის მიღებული
       </Box>
    </Box>
  )
}
