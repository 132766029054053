
export enum ChosenEntityCondition {
  originalNew = "originalNew",
  originalUsed = "originalUsed",
  replicaNew = "replicaNew",
}

export interface Icolumns {
  id: string;
  label: string;
  minWidth: number;
}

export interface IOfferRow {
  offer_id: string | number;
  buyer_name:string,
  buyer_phone:string,
  seller_name:string,
  seller_phone:string,
  buyer_info: any;
  seller_info: any;
  car_manufacturer: string;
  car_model: string;
  part_name: string;
  part_number: string;
  catalog_info: any;
  grade: string;
  warranty: number | string;
  price: number;
  preparation_time: string;
  status: number | string;
  timer: number | string;
  created_at: string;
}
