import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Chip} from "@mui/material";
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import React, { useCallback, useState } from "react";
import Moment from "react-moment";
import "moment/locale/ka";
import {Icolumns, IOfferRow } from "../../../views/customers/offers/interface";
import ActionButton from "../../common/buttons/action-button";
import { StatusDrop } from "../../common/statusdrop/status-drop";

const columns = [
  { id: "id", label: "ID", minWidth: 20 },
  { id: "buyer_name", label: "მყიდველის სახ/გვარი", minWidth: 120},
  { id: "buyer_phone", label: "მყიდველის მობ", minWidth: 100 },
  { id: "seller_name", label: "გამყიდველის სახ/გვარი", minWidth: 120 },
  { id: "seller_phone", label: "გამყიდველის მობ", minWidth: 100 },
  { id: "part_name", label: "ნაწილის სახ", minWidth: 120 },
  { id: "part_number", label: "ნაწილის ნომერი", minWidth: 120 },
  { id: "status", label: "სტატუსი", minWidth: 130 },
  { id: "offer_creation", label: "შემოსვლის თარიღი", minWidth: 120 },
  { id:'details', label:'დეტალურად', minWidth: 80},
];

const OfferTable = ({ rows, setModalStatus}: { rows: IOfferRow[], setModalStatus:React.Dispatch<React.SetStateAction<{modalStatus:boolean, block:string}>>}) => {
  return (
    <Paper sx={{ width: "100%", overflow: "hidden", mb:0, minHeight:600}}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table"> 
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  sx={{ fontSize: "12px" }}
                  key={column.id}
                  align="center"
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              return <HorizontalRow row={row} columns={columns} setModalStatus={setModalStatus} />;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

const HorizontalRow = ({row,columns,setModalStatus}: {row: IOfferRow;columns: Icolumns[], setModalStatus:React.Dispatch<React.SetStateAction<{modalStatus:boolean, block:string, modalData:{}}>>}) => {

  const renderRowNodes = useCallback((columnType, value, row)=>{
    const components = {
      details:<ActionButton title="დეტალურად"  action={()=>setModalStatus({modalStatus:true, block:'buyer', modalData:{...row}})} fontSize={'11px'} icon={<OpenInFullIcon style={{fontSize:'11px'}} />}/>,
      status:<StatusDrop rowData={row}/>,
      offer_creation: <Moment locale="ka" format="LLL" date={value} />
    }
    if (components.hasOwnProperty(columnType)) {
      return components[columnType];
    } else {
      return value ? value : '---';
    }
},[])

  const [active, setActive] = useState(false);
  return (
    <TableRow
      hover
      role="checkbox"
      selected={active}
      tabIndex={1}
      key={row.offer_id}
      onClick={() => setActive((prev) => !prev)}
    >
      {columns.map((column) => {
        const value = row[column.id];
        const columnName = column.id;
        return (
          <TableCell key={column.id} align="center" sx={{ fontSize: "13px" }}>
             {renderRowNodes(columnName,value,row)}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default OfferTable;
