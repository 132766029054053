import { Box, Chip, Divider, Typography } from "@mui/material";
import React from "react";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import GridRow from "../../../common/grid-row/grid-row";
import { ChosenEntityTitle } from "../../../../views/customers/offers/helpers";
import PartInfoDetails from "./part-info-details";

interface IpartInfo {
  car: {
    title: string;
    brand: {
      title: string;
    };
    model: {
      title: string;
    };
    parameters: {
      name: string;
      idx: string | number;
      value: string;
    }[];
  };
  part: {
    title: string;
    vendor_id: string | number;
    info: {
      variations: any[];
    };
  };
}

const PartInfo = ({
partInfo,
  isOfferMade,
}: {
  partInfo: any;
  isOfferMade: boolean;
}) => {
  const {
    car,
    part: {
      title: part_title,
      vendor_id: part_id,
      warranty,
      price,
      cond,
      grade,
      preparation_time,
      additional_comment,
      seller_comment,
      seller_images,
      buyer_images,
      buyer_comment,
    },
  } = partInfo;

  const renderWarrantyTitle = warranty == -1 ? "გარანტიის გარეშე" : `${warranty} თვე`;
  return (
    <Box>
      {/* car info */}
      <Box mt={3}>
        <Box>
          <Divider sx={{ borderColor: "#2D3748" }} textAlign="left">
            <Chip label="ინფორმაცია მანქანის შესახებ" variant="outlined" />
          </Divider>
          <Box maxWidth={500}>
            <GridRow
              keyName="მანქანის მწარმოებელი:"
              children={
                <Chip label={car?.brand?.title} icon={<DirectionsCarIcon />} />
              }
            />
            <GridRow
              keyName="მანქანის მოდელი:"
              children={
                <Chip
                  label={`${car?.model.title} ${car?.title}`}
                  icon={<DirectionsCarIcon />}
                />
              }
            />
          </Box>
          <Box>
            {car?.parameters?.length ? (
              <Box mt={5}>
                <Divider sx={{ borderColor: "#2D3748" }} textAlign="left">
                  <Chip label="არჩეული სპეციფიკაციები" variant="outlined" />
                </Divider>
                <Box maxWidth={500}>
                  {car?.parameters.map((spec) => (
                    <GridRow
                      key={spec.idx}
                      keyName={`${spec.name}:`}
                      children={<Chip label={spec.value} />}
                    />
                  ))}
                </Box>
              </Box>
            ) : null}
          </Box>
        </Box>
      </Box>
      <Box mt={3}>
        <Box>
          <Divider sx={{ borderColor: "#2D3748" }} textAlign="left">
            <Chip label={isOfferMade ? "შეთავაზებული ნაწილი" : 'ნაწილი'} variant="outlined" />
          </Divider>
          <Box maxWidth={500}>
            <GridRow
              keyName="ნაწილის დასახელება:"
              children={<Chip label={<a  href={`https://www.google.com/search?q=${part_title}`} target="_blank">{part_title}</a>} />}
            />

            <GridRow
              keyName="ნაწილის ID:"
              children={<Chip label={part_id ? <a  href={`https://www.google.com/search?q=${part_id}&sxsrf=ALiCzsbb67z04WhYtIicjlELHZ9JSFInhQ:1659090489519&source=lnms&tbm=isch&sa=X&ved=2ahUKEwiujJza8Z35AhUOgf0HHci5COcQ_AUoAXoECAIQAw&biw=1440&bih=821&dpr=1`} target="_blank">{part_id}</a> :  "--"} />}
            />

            {cond && <GridRow
              keyName="ნაწილის მდგომარეობა"
              children={<Chip label={ChosenEntityTitle(cond)} />}
            />}

            {buyer_comment ?             
            <GridRow
                keyName="მყიდველის კომენტარი"
                children={
                  <Typography
                    sx={{ fontSize: 14, mb: 0, mt: 0.5 }}
                    variant="body1"
                    gutterBottom
                  >
                    "{buyer_comment}"
                  </Typography>
                }
              /> : null}
            {price ? (
              <Box>
                <GridRow
                  keyName="ნაწილის ფასი"
                  children={<Chip label={`${price} ლარი`} />}
                />                
                {grade ? (
                  <GridRow keyName="გრეიდი" children={<Chip label={grade} />} />
                ) : null}
                <GridRow
                  keyName="გარანტია"
                  children={<Chip label={renderWarrantyTitle} />}
                />
                <GridRow
                  keyName="ნაწილის მომზადების დრო"
                  children={<Chip label={`${preparation_time} საათი`} />}
                />
                <GridRow
                  keyName="გამყიდველის კომენტარი"
                  children={
                    <Typography
                      sx={{ fontSize: 14, mb: 0, mt: 0.5 }}
                      variant="body1"
                      gutterBottom
                    >
                      "{seller_comment}"
                    </Typography>
                  }
                />
                <GridRow
                  keyName="დამატებითი კომენტარი (შეთავაზებისას)"
                  children={
                    <Typography
                      sx={{ fontSize: 14, mb: 0, mt: 0.5 }}
                      variant="body1"
                      gutterBottom
                    >
                      "{additional_comment}"
                    </Typography>
                  }
                />
              </Box>
            ) : null}
          </Box>
        </Box>
      </Box>
      <Box mt={3}>
         <PartInfoDetails part={partInfo.part} />
      </Box>
    </Box>
  );
};

export default PartInfo;
