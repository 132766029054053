/** @format */

// prettier-ignore
/* eslint-disable */

import { AppBar, Box, Toolbar, IconButton} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import React from "react";

const Header = ({barSatus, setBarStatus}) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        sx={{
          backgroundColor: "#ffffff",
          margin: 0,
          boxShadow: 3,
        }}
      >
        <Toolbar>
          <>
          {!barSatus ?  
             <IconButton onClick={()=>setBarStatus(true)} sx={{mr:2}}>
                <MenuIcon/>
             </IconButton> : null
          }
          </>
          <Box
            display={"flex"}
            justifyContent={barSatus ? "flex-end" : "space-between"}
            flex={1}
            alignItems="center"
          >
            <Box
              component="img"
              sx={{
                height: 70,
                width: 70,
                objectFit: "contain",
              }}
              alt="The house from the offer."
              src={require("../../../images/logo.png")}
            />
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
