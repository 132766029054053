import React, { useEffect, useState } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import { useQuery } from "react-query";
import { Box, Chip } from "@mui/material";
import OffersController from "../../../controller/offers";
import { useToasts } from "react-toast-notifications";
import moment from "moment";

const statuses = [
  { status: null, title: "არჩევა", time: 0 },
  { status: 1, title: "მიმდინარე", time: 0 },
  { status: 2, title: "ელოდება მომხმარებლის პასუხს", time: 0 },
  { status: 3, title: "პასუხის გაცემის დრო ამოიწურა(24სთ)", time: 0 },
  { status: 4, title: "შეთავაზების დრო ამოიწურა(40წთ)", time: 0 },
  { status: 5, title: "არ მაქვს მარაგში", time: 0 },
  { status: 6, title: "მუშავდება(10 წთ)", time: 0 },
  { status: 8, title: "კორექტირება მოთხოვნილი", time: 0 },
  { status: 9, title: "უარყოფილი შეკვეთა", time: 0 },
  { status: 10, title: "დადასტურებული შეკვეთა", time: 0 },
  { status: 11, title: "შეკვეთა მზადდება", time: 0 },
  { status: 13, title: "კურიერზე გადაცემული", time: 0 },
  { status: 14, title: "წარმატებული", time: 0 },
  { status: 18, title: "კურიერის მოლოდინში", time: 0 },
  { status: 20, title: "დაწუნებული (კურიერის მიერ)", time: 0 },
  { status: 21, title: "დაწუნებული (კლიენტის მიერ)", time: 0 },
  { status: 22, title: "ვერ ჩაბარებული", time: 0 },
  { status: 23, title: "დაბრუნებული", time: 0 },
];

export const StatusDrop = ({ rowData }) => {
  if (!rowData.status) {
    const [withoutOfferStatus, setWithoutOfferStatus] = useState(
      moment(rowData.expired_at).isAfter(moment()) ? 1 : 0
    );

    const reschedule = async (event) => {
      try {
        await OffersController.reschedule(
          event.target.value,
          rowData.request_id
        );
        setWithoutOfferStatus(event.target.value);
      } catch (e) {
        addToast(`სერვერთან დაკავშირებისას მოხდა შეცდომა, სცადეთ თავიდან!`, {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
      }
    };

    return (
      <Select
        sx={{ fontSize: "10px" }}
        labelId="demo-select-small"
        id="demo-select-small"
        size="small"
        value={withoutOfferStatus}
        onChange={reschedule}
      >
        <MenuItem sx={{ fontSize: "9px" }} value={0}>
          <Box display="flex" alignItems="center" sx={{ fontSize: "9px" }}>
            არჩევა
          </Box>
        </MenuItem>
        <MenuItem sx={{ fontSize: "9px" }} value={1}>
          <Box display="flex" alignItems="center" sx={{ fontSize: "9px" }}>
            მიმდინარე
          </Box>
        </MenuItem>
      </Select>
    );
  }

  const { addToast } = useToasts();
  const [status, setStatus] = useState(rowData.status?.status);
  const target = statuses?.find(
    (obj) => obj?.status === rowData.status?.status
  );

  Object.assign(target, {
    status: rowData.status?.status,
    title: rowData.status?.title,
    time: rowData.status?.time,
  });

  const changeStatus = (event) => {
    setStatus(event.target.value);
    setTimeout(() => {
      refetch();
    }, 1000);
  };

  const secondsToHms = (d: number) => {
    d = Number(d);
    let hDisplay = Math.floor(d / 3600);
    let mDisplay = Math.floor((d % 3600) / 60);
    let sDisplay = Math.floor((d % 3600) % 60);
    if (d < 0) {
      return `00:00`;
    } else if (d > 3599) {
      return `${hDisplay}სთ:${
        mDisplay < 10 ? `0${mDisplay}წთ` : `${mDisplay}წთ`
      }`;
    } else {
      return `${mDisplay}:${sDisplay < 10 ? `0${sDisplay}` : `${sDisplay}`}`;
    }
  };

  const changeVerificationStatus = (status, uuid) => {
    return OffersController.changePartStatus(status, uuid);
  };

  const { isFetching, refetch } = useQuery(
    ["changeStatus", status],
    () => changeVerificationStatus(status, rowData.id),
    {
      enabled: false,
      onError: (error) => {
        addToast(`სერვერთან დაკავშირებისას მოხდა შეცდომა, სცადეთ თავიდან!`, {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
      },
    }
  );

  if (isFetching) {
    return <CircularProgress size={40} />;
  }

  return (
    <Select
      sx={{ fontSize: "10px" }}
      labelId="demo-select-small"
      id="demo-select-small"
      value={status}
      size="small"
      onChange={changeStatus}
    >
      {statuses.map((el) => (
        <MenuItem
          key={el.status + Date.now()}
          sx={{ fontSize: "9px" }}
          value={el.status}
        >
          <Box display="flex" alignItems="center" sx={{ fontSize: "9px" }}>
            {el.title}
            {el.time && el.status == rowData.status?.status ? (
              <Chip
                label={secondsToHms(el.time)}
                sx={{ marginLeft: 3, fontSize: 10, height: 20 }}
              />
            ) : null}
          </Box>
        </MenuItem>
      ))}
    </Select>
  );
};

//მიმდინარე თავისი ტაიმერით 40 წუთი, გაგზავნილი 24 ველოდებით მომხმარებელს, 24 საათი ამოიწრუად არ შეძენილი დეაქტივირებული, დრო ამოეწურა 40 წუთიან ტაიმერს გამორჩენილი, 40 წუთიანი არ მაქვს მარაგში, დასადასტურებელი მომლოდინე შეკვეთა მუშავდება 10 წუთუანი ტაიმერი, კორექტირების მოთხოვნა, კორექტირებული, უარყოფილი გაუქმებული შეკვეთა, დადასტურებული შეკვეთა კურიერის მოლოდინში, შეკვეთა მზადდება, დაქენსელებული დაწუნებული, კურიერზე გადაცემული, წარმატებული დასრულებული ჩაბარებული, დასაბრუნებელი, დაბრუნებული, გაუქმება ნეიტრალური სტატუსით
