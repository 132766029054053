/** @format */

import InventorsAPI from "../../config/api/index";

export default class OffersController {
  public static async getOfferList(page:number, search:any) {
    const params ={
      ...search
   }
    const response = await InventorsAPI.get(`/admin/offers?page=${page}`, {params, timeout:20000}, );
    return response.data;
  }

  public static async changePartStatus(status, partUid) {
    const response = await InventorsAPI.patch(`/admin/offers/${partUid}`, {status:status}, );
    return response.data;
  }

  public static async reschedule(status, partUid) {
    const response = await InventorsAPI.post(`/admin/request/${partUid}`, {status:status}, );
    return response.data;
  }

}
