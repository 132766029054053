import { Box, Chip, Divider } from "@mui/material";
import React from "react";
import PersonIcon from "@mui/icons-material/Person";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
import BadgeIcon from "@mui/icons-material/Badge";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import GradeIcon from "@mui/icons-material/Grade";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import GridRow from "../../../common/grid-row/grid-row";

interface IsellerInfo {
  address:string,
  firstname:string,
  lastname:string,
  mobile:string,
  notifiable:boolean
  rating:number
  personal_id:number|string
  seller_name2:string
  seller_type:string,
  legal:{
    title:string, 
    id_code:string
  }
}

const SellerInfo = ({sellerInfo}:{sellerInfo:IsellerInfo}) => {
  
  const {address, firstname, lastname, mobile, notifiable, rating, personal_id, seller_name2, legal, seller_type}  = sellerInfo
  return (
    <Box py={1}>
      <Box>
        <GridRow
          keyName="გამყიდველის სახელი და გვარი (დარეგისტრირებული)"
          children={
            <Box display={'flex'} alignItems={'center'}>
                <Chip
                sx={{ py: 2, px: 2 }}
                icon={<PersonIcon />}
                label={firstname ? `${firstname} ${lastname}` : '---'}
              />
            </Box>
          }
        />
        {/* <Divider sx={{ borderColor: "#2D3748" }} />
         <GridRow
          keyName="გამყიდველის სახელი და გვარი (შეთავაზების გაგზავნისას)"
          children={
            <Box display={'flex'} alignItems={'center'}>
                <Chip
                sx={{ py: 2, px: 2 }}
                icon={<PersonIcon />}
                label={seller_name2}
              />
            </Box>
          }
        /> */}
        <Divider sx={{ borderColor: "#2D3748" }} />
        <GridRow
          keyName="გამყიდველის ტელეფონის ნომერი:"
          children={
            <Chip
              sx={{ py: 2, px: 2 }}
              icon={<LocalPhoneIcon />}
              label={mobile}
            />
          }
        />
        <Divider sx={{ borderColor: "#2D3748" }} />
        <GridRow
          keyName="იურიდიული პირის დასახელება:"
          children={
            <Chip
              sx={{ py: 2, px: 2 }}
              icon={<AssuredWorkloadIcon />}
              label={legal?.title ?? 'არ არის მითითებული'}
            />
          }
        />
        <Divider sx={{ borderColor: "#2D3748" }} />
        <GridRow
          keyName="პირადი ნომერი / საიდენტიფიკაციო:"
          children={
            <Chip
              sx={{ py: 2, px: 2 }}
              icon={<BadgeIcon />}
              label={`პირადი ნომერი: ${personal_id ?? '---'} ${legal?.id_code ? ('/ საიდენტიფიკაციო კოდი:' + legal?.id_code) : ''}`}
            />
          }
        />
        <Divider sx={{ borderColor: "#2D3748" }} />
        <GridRow
          keyName="ტიპი (იმპორტი/ექსპორტი):"
          children={
            <Chip
              sx={{ py: 2, px: 2 }}
              icon={<ImportExportIcon />}
              label={seller_type == 'local' ? 'ნაწილებ ადგილზეა' : 'იმპორტი'}
            />
          }
        />
        <Divider sx={{ borderColor: "#2D3748" }} />
        <GridRow
          keyName="გამყიდველის / საწყობის მისამართი:"
          children={
            <Chip
              sx={{ py: 2, px: 2 }}
              icon={<LocationOnIcon />}
              label={address ?? '--'}
            />
          }
        />
        <Divider sx={{ borderColor: "#2D3748" }} />
        <GridRow
          keyName="შეტყობინებები:"
          children={
            <Chip
              color={notifiable ? 'success' : 'error'}
              sx={{ py: 2, px: 2 }}
              icon={notifiable ? <NotificationsActiveIcon/> : <NotificationsOffIcon />}
              label={notifiable ? 'ჩართული' : 'გამორთული'}
            />
          }
        />
        <Divider sx={{ borderColor: "#2D3748" }} />
        <GridRow
          keyName="გამყიდველის რეიტინგი:"
          children={
            <Chip sx={{ py: 2, px: 2 }} icon={<GradeIcon />} label={address ? rating : '--'} />
          }
        />
      </Box>
    </Box>
  );
};

export default SellerInfo;
