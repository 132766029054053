import { Box, Divider, Chip, Grid } from "@mui/material";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import PersonIcon from "@mui/icons-material/Person";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
import BadgeIcon from "@mui/icons-material/Badge";
import GridRow from "../../../common/grid-row/grid-row";
import React from "react";

interface IBuyerInfo {
  firstname:string
  lastname:string,
  mobile:number|string 
  address:{
    formatted_address:string
  }
  id:string|number
  legal:{
     title:string,
  } | null
}

const BuyerInfo = ({buyerInfo}:{buyerInfo:IBuyerInfo}) => {
  console.log(buyerInfo);
  
  const {firstname, lastname, mobile, id, legal, address:{formatted_address}} = buyerInfo
  return (
    <Box py={2}>
      <Box>
        <GridRow
          keyName="მყიდველის სახელი და გვარი:"
          children={
            <Chip
              sx={{ py: 2, px: 2 }}
              icon={<PersonIcon />}
              label={`${firstname} ${lastname}`}
            />
          }
        />
        <Divider sx={{ borderColor: "#2D3748" }} />
        <GridRow
          keyName="მყიდველის ტელეფონის ნომერი:"
          children={
            <Chip
              sx={{ py: 2, px: 2 }}
              icon={<LocalPhoneIcon />}
              label={mobile}
            />
          }
        />
        <Divider sx={{ borderColor: "#2D3748" }} />
        <GridRow
          keyName="მყიდველის მისამართი:"
          children={
            <Chip
              sx={{ py: 2, px: 2 }}
              icon={<LocationOnIcon />}
              label={formatted_address}
            />
          }
        />
        <Divider sx={{ borderColor: "#2D3748" }} />
        <GridRow
          keyName="იურიდიული პირის დასახელება:"
          children={
            <Chip
              sx={{ py: 2, px: 2 }}
              icon={<AssuredWorkloadIcon />}
              label={legal ? legal.title : 'არ არის მითითებული'}
            />
          }
        />
        {/* <Divider sx={{ borderColor: "#2D3748" }} />
        <GridRow
          keyName="პირადი ნომერი:"
          children={
            <Chip
              sx={{ py: 2, px: 2 }}
              icon={<BadgeIcon />}
              label="33001076844"
            />
          }
        />
        <Divider sx={{ borderColor: "#2D3748" }} /> */}
      </Box>
    </Box>
  );
};

export default BuyerInfo;
