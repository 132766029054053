/** @format */
import React  from "react";
import { Box, Divider, Drawer, IconButton } from "@mui/material";
import { NavItem } from "./nav-item/nav-item";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ActiveUserInfo from "../../auth/active-user-info/active-user-info";
import { RouteLink } from "../../../route-links";



const style = {
  top: "47%",
  position: "absolute",
  right: "-8px",
  backgroundColor: "#ffffff",
  width: 64,
  height: 64,
  borderTopRightRadius: "10px",
  borderBottomRightRadius: "10px",
  "&:hover":{
    backgroundColor: "#ffffff"
  }
}

export const DashboardSidebar = ({ onLogout, setBarStatus}:{onLogout:()=>void, setBarStatus:any}) => {
  const content = (
    <Box
      sx={{
        overflowX:'hidden',
        position:'relative',
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box
        sx={{
          px: 2,
          py: "20px",
          borderRadius: 1,
        }}
      >
        <ActiveUserInfo action={onLogout} />
      </Box>
      <Box>
      <Divider
        sx={{
          borderColor: "#2D3748",
          my: 3,
          width: "100%",
        }}
      />
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <IconButton sx={{...style}} onClick={()=>setBarStatus(false)}>
            <KeyboardArrowLeftIcon/>
        </IconButton>
        {RouteLink.map((item) => (
          <NavItem key={item.title} href={item.href} isExternal={item.isExternal}  title={item.title} icon={item.icon} />
        ))}


      </Box>
      <Divider sx={{ borderColor: "#2D3748" }} />
    </Box>
  );

  return (
    <Drawer
      anchor="left"
      open
      sx={{
        width: "inherit",
        maxWidth: "inherit",
      }}
      PaperProps={{
        sx: {
          backgroundColor: "neutral.900",
          color: "#FFFFFF",
          width: "inherit",
          maxWidth: "inherit",
        },
      }}
      variant="permanent"
    >
      {content}
    </Drawer>
  );
};

