import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import CloseRounded from "@mui/icons-material/CloseRounded";
import { Box, IconButton, Typography } from "@mui/material";



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SlideInModal = ({status, maxWidth, title, children, setClose}) => {
  return (
    <div>
      <Dialog
        onClose={setClose}
        open={status}
        TransitionComponent={Transition}
        maxWidth={maxWidth}
        keepMounted
        fullWidth={true}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
           <Box display={'flex'} justifyContent={'space-between'}>
             {title}
             <IconButton onClick={setClose}>
                  <CloseRounded/>
             </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {children}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SlideInModal;
