/** @format */

import axios from "axios";
export const baseURL =  "https://test2.app.viaro.grena.ge/";
const InventorsAPI = axios.create({
  baseURL: baseURL+"api",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  timeout: 5000,
});




InventorsAPI.interceptors.request.use(
  function(config) {
    const token = JSON.parse(localStorage?.getItem("idToken")); 
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);


export default InventorsAPI;
