import React, { useEffect, useState } from "react";
import { Box, Paper, Typography } from "@mui/material";
import AddCircle from "@mui/icons-material/AddCircle";
import ManageSearch from "@mui/icons-material/ManageSearch";
import CloseRounded from "@mui/icons-material/CloseRounded";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import DynamicTable from "../../../components/common/table/table";
import ActionButton from "../../../components/common/buttons/action-button";
import SlideInModal from "../../../components/common/slide-in-modal/slide-in-modal";
import { AddSellers } from "../../../components/dashboard/sellers/add-sellers/add-sellers";
import { sellerFilter } from "./helpers/filter";
import DynamicFilter from "../../../components/common/dynamic-filter/dynamic-filter";
import SellerRatingDetails from "../../../components/dashboard/seller-rating/seller-rating-details/seller-rating-details";

const Sellers = () => {
  const [openAddSellerModal, setOpenAddSellerModal] = useState(false);
  const [rows, setRows] = useState([]);
  const [filter, setFilter] = useState(false);
  const [rateModal, setOpenRateModal] = useState(false);
  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };

  const callFilterApi = (data) => {
    console.log(data);
  };

  function prepareTableData() {
    let arr = mainData.map((data) => {
      return { ...data };
    });
    setRows(arr);
  }

  useEffect(() => {
    prepareTableData();
  }, []);

  return (
    <Box sx={{ py: 3, px: 2 }}>
      <Box display={"flex"} justifyContent={"flex-end"} pb={2}>
        <Box mx={3}>
          <ActionButton
            title="ფილტრები"
            icon={filter ? <CloseRounded /> : <ManageSearch />}
            action={() => setFilter((prev) => !prev)}
          />
        </Box>
        <ActionButton
          title="გამყიდველის რეგისტრაცია"
          icon={<AddCircle />}
          action={() => setOpenAddSellerModal(true)}
        />
      </Box>
      <Box>
        {filter ? (
          <DynamicFilter
            fields={sellerFilter}
            saveData={callFilterApi}
            buttonTitle={"ძებნა"}
          />
        ) : null}
      </Box>
      <DynamicTable tableColumns={tableColumns} tableRows={rows} action={()=>setOpenRateModal(true)} />
      <SlideInModal
        status={openAddSellerModal}
        title="გამყიდველის დამატება"
        maxWidth="lg"
        setClose={() => setOpenAddSellerModal(false)}
      >
        <AddSellers />
      </SlideInModal>
      <SlideInModal
        status={rateModal}
        title="გამყიდველის რეიტინგი"
        maxWidth="md"
        setClose={() => setOpenRateModal(false)}
      >
       <SellerRatingDetails/>
      </SlideInModal>
      <Box>
        <Paper sx={{ p: 2, mb: 4, mt:2}} elevation={2}>
          <Stack spacing={2} direction="row" display='flex' justifyContent="center">
            <Pagination count={10} page={page} onChange={handleChange} />
          </Stack>
        </Paper>
      </Box>
    </Box>
  );
};

export default Sellers;

const tableColumns = [
  { id: "name", label: "სახელი", minWidth: 170 },
  { id: "surname", label: "გვარი", minWidth: 100 },
  {
    id: "mobile",
    label: "ტელეფონის ნომერი",
    minWidth: 170,
    align: "right",
  },
  {
    id: "rating",
    label: "რეიტინგი",
    minWidth: 170,
    align: "right",
  },
  {
    id: "personalId",
    label: "პირადი ნომერი",
    minWidth: 170,
    align: "right",
  },
  {
    id: "verification_status",
    label: "სტატუსი",
    minWidth: 170,
    align: "right",
  },
  {
    id: "notification",
    label: "შეტყობინებების მიღება",
    minWidth: 250,
    align: "right",
  },
  {
    id: "iban_number",
    label: "ანგარიშის ნომერი",
    minWidth: 170,
    align: "right",
  },
  {
    id: "default_address",
    label: "დეფოლტ მისამართი",
    minWidth: 250,
    align: "right",
  },
  {
    id: "type",
    label: "ტიპი",
    minWidth: 170,
    align: "right",
  },
  {
    id: "shps",
    label: "შპს სახელწოდება",
    minWidth: 170,
    align: "right",
  },
  {
    id: "indentify_code",
    label: "საიდენტიფიკაციო კოდი",
    minWidth: 250,
    align: "right",
  },
  {
    id: "position",
    label: "თანამდებობა",
    minWidth: 170,
    align: "right",
  },
  {
    id: "one_time_password",
    label: "ერთჯერადი პაროლი",
    minWidth: 250,
    align: "right",
  },
  {
    id: "registration_date",
    label: "რეგისტრაციის თარიღი",
    minWidth: 250,
    align: "right",
  },
  {
    id: "edit",
    label: "რედაქტირება",
    minWidth: 250,
    align: "right",
  },
  {
    id: "delete",
    label: "წაშლა",
    minWidth: 250,
    align: "right",
  },
];

const mainData = [
  {
    name: "kuji",
    surname: "bolkb",
    mobile: "599803349",
    rating: "9.8",
    personalId: "33001076844",
    verification_status: 2,
    notification: 1,
    iban_number: "GE21231231231231231233",
    default_address: "KOSTAVA STREET",
    type: "import",
    shps: "შპს სპინომ",
    indentify_code: "2123123123222",
    position: "developer",
    one_time_password: "xssawswwwqw221",
    registration_date: "21/02/2012",
    id: "asdasdasdasd221",
  },
  {
    name: "kuji",
    surname: "bolkb",
    mobile: "599803349",
    rating: "9.8",
    personalId: "33001076844",
    verification_status: 2,
    notification: 1,
    iban_number: "GE21231231231231231233",
    default_address: "KOSTAVA STREET",
    type: "import",
    shps: "შპს სპინომ",
    indentify_code: "2123123123222",
    position: "developer",
    one_time_password: "xssawswwwqw221",
    registration_date: "21/02/2012",
    id: "asdasdasdasd221",
  },
  {
    name: "kuji",
    surname: "bolkb",
    mobile: "599803349",
    rating: "9.8",
    personalId: "33001076844",
    verification_status: 2,
    notification: 1,
    iban_number: "GE21231231231231231233",
    default_address: "KOSTAVA STREET",
    type: "import",
    shps: "შპს სპინომ",
    indentify_code: "2123123123222",
    position: "developer",
    one_time_password: "xssawswwwqw221",
    registration_date: "21/02/2012",
    id: "asdasdasdasd221",
  },
  {
    name: "kuji",
    surname: "bolkb",
    mobile: "599803349",
    rating: "9.8",
    personalId: "33001076844",
    verification_status: 2,
    notification: 1,
    iban_number: "GE21231231231231231233",
    default_address: "KOSTAVA STREET",
    type: "import",
    shps: "შპს სპინომ",
    indentify_code: "2123123123222",
    position: "developer",
    one_time_password: "xssawswwwqw221",
    registration_date: "21/02/2012",
    id: "asdasdasdasd221",
  },
  {
    name: "kuji",
    surname: "bolkb",
    mobile: "599803349",
    rating: "9.8",
    personalId: "33001076844",
    verification_status: 2,
    notification: 1,
    iban_number: "GE21231231231231231233",
    default_address: "KOSTAVA STREET",
    type: "import",
    shps: "შპს სპინომ",
    indentify_code: "2123123123222",
    position: "developer",
    one_time_password: "xssawswwwqw221",
    registration_date: "21/02/2012",
    id: "asdasdasdasd221",
  },
  {
    name: "kuji",
    surname: "bolkb",
    mobile: "599803349",
    rating: "9.8",
    personalId: "33001076844",
    verification_status: 2,
    notification: 1,
    iban_number: "GE21231231231231231233",
    default_address: "KOSTAVA STREET",
    type: "import",
    shps: "შპს სპინომ",
    indentify_code: "2123123123222",
    position: "developer",
    one_time_password: "xssawswwwqw221",
    registration_date: "21/02/2012",
    id: "asdasdasdasd221",
  },
  {
    name: "kuji",
    surname: "bolkb",
    mobile: "599803349",
    rating: "9.8",
    personalId: "33001076844",
    verification_status: 2,
    notification: 1,
    iban_number: "GE21231231231231231233",
    default_address: "KOSTAVA STREET",
    type: "import",
    shps: "შპს სპინომ",
    indentify_code: "2123123123222",
    position: "developer",
    one_time_password: "xssawswwwqw221",
    registration_date: "21/02/2012",
    id: "asdasdasdasd221",
  },
  {
    name: "kuji",
    surname: "bolkb",
    mobile: "599803349",
    rating: "9.8",
    personalId: "33001076844",
    verification_status: 2,
    notification: 1,
    iban_number: "GE21231231231231231233",
    default_address: "KOSTAVA STREET",
    type: "import",
    shps: "შპს სპინომ",
    indentify_code: "2123123123222",
    position: "developer",
    one_time_password: "xssawswwwqw221",
    registration_date: "21/02/2012",
    id: "asdasdasdasd221",
  },
  {
    name: "kuji",
    surname: "bolkb",
    mobile: "599803349",
    rating: "9.8",
    personalId: "33001076844",
    verification_status: 2,
    notification: 1,
    iban_number: "GE21231231231231231233",
    default_address: "KOSTAVA STREET",
    type: "import",
    shps: "შპს სპინომ",
    indentify_code: "2123123123222",
    position: "developer",
    one_time_password: "xssawswwwqw221",
    registration_date: "21/02/2012",
    id: "asdasdasdasd221",
  },
  {
    name: "kuji",
    surname: "bolkb",
    mobile: "599803349",
    rating: "9.8",
    personalId: "33001076844",
    verification_status: 2,
    notification: 1,
    iban_number: "GE21231231231231231233",
    default_address: "KOSTAVA STREET",
    type: "import",
    shps: "შპს სპინომ",
    indentify_code: "2123123123222",
    position: "developer",
    one_time_password: "xssawswwwqw221",
    registration_date: "21/02/2012",
    id: "asdasdasdasd221",
  },
  {
    name: "kuji",
    surname: "bolkb",
    mobile: "599803349",
    rating: "9.8",
    personalId: "33001076844",
    verification_status: 2,
    notification: 1,
    iban_number: "GE21231231231231231233",
    default_address: "KOSTAVA STREET",
    type: "import",
    shps: "შპს სპინომ",
    indentify_code: "2123123123222",
    position: "developer",
    one_time_password: "xssawswwwqw221",
    registration_date: "21/02/2012",
    id: "asdasdasdasd221",
  },

  {
    name: "kuji",
    surname: "bolkb",
    mobile: "599803349",
    rating: "9.8",
    personalId: "33001076844",
    verification_status: 2,
    notification: 1,
    iban_number: "GE21231231231231231233",
    default_address: "KOSTAVA STREET",
    type: "import",
    shps: "შპს სპინომ",
    indentify_code: "2123123123222",
    position: "developer",
    one_time_password: "xssawswwwqw221",
    registration_date: "21/02/2012",
    id: "asdasdasdasd221",
  },
  {
    name: "kuji2",
    surname: "bolkb",
    mobile: "599803349",
    rating: "9.8",
    personalId: "33001076844",
    verification_status: 1,
    notification: 1,
    iban_number: "GE21231231231231231233",
    default_address: "KOSTAVA STREET",
    type: "import",
    shps: "შპს სპინომ",
    indentify_code: "2123123123222",
    position: "developer",
    one_time_password: "xssawswwwqw221",
    registration_date: "21/02/2012",
    id: "asdasdasdasd2",
  },
];
