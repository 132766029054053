import { Box } from "@mui/material";
import React, { useState } from "react";
import DynamicForm from "../../../common/dynamic-form/dynamic-form";


let initialState = {
  one_time_password: "",
  position: "",
  indentify_code: "",
  shps: "",
  type: "",
  mobile: "",
  name: "",
  surname: "",
  personalId:""
};

export const AddBuyers = () => {
  const [userInput, setUserInput] = useState(initialState);
  
  const mockApiCall = (data)=>{
    console.log(data);
  }

  return (
    <Box>
      <DynamicForm
        fields={sellerRegistration}
        userInput={userInput}
        saveData={mockApiCall}
        /* loadingState={} */
        buttonTitle={'გამყიდველის დამატება'}

      />
    </Box>
  );
};

const sellerRegistration = [
  {
    key: "name",
    label: "სახელი",
    value: "",
    node: "input",
    inputType: "text",
    inputValidation: {
      required: {
        value: true,
        message: "ველის მითითება სავალდებულოა!",
      },
    },
  },
  {
    key: "surname",
    label: "გვარი",
    value: "",
    node: "input",
    inputType: "text",
    inputValidation: {
      required: {
        value: true,
        message: "ველის მითითება სავალდებულოა!",
      },
    },
  },
  {
    node: "input",
    key: "mobile",
    label: "ტელეფონის ნომერი",
    value: "",
    inputType: "number",
    inputValidation: {
      required: {
        value: true,
        message: "ველის მითითება სავალდებულოა!",
      },
      pattern: {
        value: /^(0|[1-9]\d*)(\.\d+)?$/,
        message: "შეიყვანეთ მხოლოდ რიცხვები!",
      },
      minLength: {
        value: 9,
        message: "მობილურის უნდა შეიცავდეს 9 სიმბოლოს!",
      },
      maxLength: {
        value: 9,
        message: "მობილურის უნდა შეიცავდეს 9 სიმბოლოს!",
      },
    },
  },
  {
    key: "personalId",
    label: "პირადი ნომერი",
    value: "",
    node: "input",
    inputType: "text",
    inputValidation: {
      required: {
        value: true,
        message: "ველის მითითება სავალდებულოა!",
      },
    },
  },
  {
    node: "selectbox",
    key: "type",
    label: "ტიპი",
    value: [
      { id: 1, label: "იურიდიული" },
      { id: 2, label: "ფიზიკური" },
    ],
    inputType: "text",
    inputValidation: {
      required: {
        value: true,
        message: "ველის მითითება სავალდებულოა!",
      },
    },
  },
  {
    node: "input",
    key: "shps",
    label: "შპს სახელწოდება",
    value: "",
    inputType: "text",
    inputValidation: {
      required: {
        value: true,
        message: "ველის მითითება სავალდებულოა!",
      },
    },
  },
  {
    node: "input",
    key: "indentify_code",
    label: "საიდენტიფიკაციო კოდი",
    value: "",
    inputType: "text",
    inputValidation: {
      required: {
        value: true,
        message: "ველის მითითება სავალდებულოა!",
      },
    },
  },
  {
    node: "input",
    key: "position",
    label: "თანამდებობა",
    value: "",
    inputType: "text",
    inputValidation: {
      required: {
        value: true,
        message: "ველის მითითება სავალდებულოა!",
      },
    },
  },

  {
    node: "input",
    key: "one_time_password",
    label: "ერთჯერადი პაროლი",
    value: "",
    inputType: "text",
    inputValidation: {
      required: {
        value: true,
        message: "ველის მითითება სავალდებულოა!",
      },
    },
  },
];
