import { Box, IconButton, Pagination } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import CloseRounded from "@mui/icons-material/CloseRounded";
import ManageSearch from "@mui/icons-material/ManageSearch";
import SyncIcon from '@mui/icons-material/Sync';
import OfferTable from "../../../components/dashboard/offers/offer-table";
import SlideInModal from "../../../components/common/slide-in-modal/slide-in-modal";
import BuyerInfo from "../../../components/dashboard/offers/buyer-info/buyer-info";
import SellerInfo from "../../../components/dashboard/offers/seller-info/seller-info";
import PartInfo from "../../../components/dashboard/offers/part-info/part-info";
import ActionButton from "../../../components/common/buttons/action-button";
import DynamicFilter from "../../../components/common/dynamic-filter/dynamic-filter";
import { offersFillter } from "./helpers";
import useGetOfferList from "./hooks/useGetOffers";
import CircularLoader from "../../../components/common/circular-loader/circular-loader";
import useWindowScroll from "../../../global/hooks/useWindowScroll";
import { EmptyResult } from "../../../components/common/empty-result/empty-result";


const Offers = () => {
  const [filter, setFilter] = useState(false);
  const [search, setSearch] = useState({});
  const [modalStatus, setModalStatus] = useState<any>({modalStatus: false, block: "", modalData:{}});
  const [page, setPage] = useState(1);
  const {data, isFetching, refetch} = useGetOfferList(page, search)
  const modalComponents = {
    buyer: <BuyerInfo buyerInfo={modalStatus.modalData?.buyer} />,
    seller:modalStatus.modalData?.isOfferMade ? <SellerInfo sellerInfo={modalStatus.modalData?.seller}/> : <EmptyResult/>,
    part: <PartInfo  partInfo={modalStatus.modalData?.part_info} isOfferMade={modalStatus.modalData?.isOfferMade}/>,
  };

  const renderModalConent = useCallback(() => {
    return modalComponents[modalStatus.block];
  }, [modalStatus.block]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleClose = (event, reason) => {
    if (!reason || reason == "backdropClick") {
      setModalStatus({ modalStatus: false, block: "", modalData:{}});
    }
  };

  const callFilterApi = (data) => {
    setSearch(data);
    setTimeout(() => {
      refetch()
    }, 500);
  };
  return (
    <Box pt={2} px={1}>
      <Box display={"flex"} justifyContent={"flex-end"} alignItems="center" pb={2}>
        <Box mx={3}>
          <IconButton sx={{backgroundColor:'neutral.900'}} title="სინქრონიზაცია" onClick={()=>refetch()}>
            <SyncIcon className={isFetching ? 'spin' : ''} sx={{color:'secondary.main'}} />
          </IconButton>
        </Box>
        <Box>
          <ActionButton
            title="ფილტრები"
            icon={filter ? <CloseRounded /> : <ManageSearch />}
            action={() => setFilter((prev) => !prev)}
          />
        </Box>
      </Box>
      <Box>
          <DynamicFilter
            display={filter ? 'block' : 'none'}
            setSearch={setSearch}
            fields={offersFillter}
            saveData={callFilterApi}
            buttonTitle={"ძებნა"}
            refetch={refetch}
            loading={isFetching}
          />
      </Box>
      {isFetching ? <CircularLoader/> : <OfferTable rows={data?.offerList ?? []} setModalStatus={setModalStatus} />}
      <Box display={'flex'} justifyContent="center" p={2}>
        <Pagination count={data?.last_page} page={page} onChange={handleChange} />
      </Box>
      <SlideInModal
        status={modalStatus.modalStatus}
        title={
          <Box display={'flex'} justifyContent="space-between" alignItems={'center'} mt={3}>
            {tabs.map((tab:Itabs)=>(
                <Box key={tab.key} onClick={()=>setModalStatus((prev)=>({...prev, block:tab.key}))} sx={{cursor:'pointer', mx:1, fontSize:16, color: modalStatus.block == tab.key ? '#1976d2' : 'inherit', borderBottom: modalStatus.block == tab.key ? '2px solid #1976d2' : 'none'}}>{tab.value}</Box>
            ))}
         </Box>
      }
        maxWidth={"lg"}
        setClose={handleClose}
      >
      {renderModalConent()}
      </SlideInModal>
    </Box>
  );
};
interface Itabs {
  key:string, 
  value:string,
}
const tabs:Itabs[] = [
  {key:'buyer', value:'მყიდველი'},
  {key:'seller',value:'გამყიდველი'},
  {key:'part', value:'ნაწილი'}
]

export default Offers;
